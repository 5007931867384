import React, { useState } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"
import Papa from "papaparse"

import DashboardLayout from "layout/DashboardLayout"
import Button from "elements/Button"
import Card from "elements/Card"
import Loading from "elements/Loading"

import extractorColumns from "./utils/extractorColumns.json"

import {
  getConsultsByTag,
  deleteConsultDocument,
} from "./services/getConsultsByTag"
import { parseForm } from "../../../../services/form"
import { generateInitialValues } from "../../../../services/context"
import { generateFormField } from "elements/Form/services/form"

const Extractor = ({ pageContext }) => {
  const [consults, setConsults] = useState(null)
  const [loading, setLoading] = useState(false)

  let { module } = pageContext
  let formFields = pageContext?.formFields?.filter(
    (field) => field?.subModule === "Extractor"
  )
  let { validationSchema } = parseForm({ formFields })
  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
  }

  const handleExtract = async (values) => {
    let key = values?.extractorTagKey
    let operation = values?.extractorOperation?.value
    let value = values?.extractorTagValue

    setConsults(null)
    setLoading(true)

    setConsults(
      await getConsultsByTag({
        key,
        operation,
        value,
        formFields: pageContext.formFields,
      })
    )

    setLoading(false)
  }

  const handleCSVDownload = () => {
    let csvData = new Blob(
      [Papa.unparse({ fields: extractorColumns, data: consults })],
      {
        type: "text/csv;charset=utf-8;",
      }
    )
    let csvURL = window.URL.createObjectURL(csvData)
    let testLink = document.createElement("a")

    testLink.href = csvURL
    testLink.setAttribute("download", `extractor.csv`)
    testLink.click()
  }

  const handleDeleteBatch = async () => {
    setLoading(true)
    await deleteConsultDocument({
      consults,
    })
    setConsults(null)

    setLoading(false)
  }

  return (
    <DashboardLayout
      seoTitle={module.title}
      title={module.title}
      pageContext={pageContext}
    >
      <div className="columns is-centered">
        <div className="column is-6">
          <Card>
            <Formik
              onSubmit={handleExtract}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({ values }) => (
                <Form>
                  {formFields.map((formField) => {
                    return generateFormField({
                      values,
                      formFields: formFields,
                      formField: formField,
                    })
                  })}
                  <div className="is-flex is-flex-direction-column is-align-items-center">
                    <Button
                      type="submit"
                      color="primary"
                      className={classNames("column pb-0 pt-0 is-6 mt-3 mb-1")}
                    >
                      Extract
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            {consults ? (
              <div className="is-flex is-flex-direction-column is-align-items-center">
                {consults?.length > 1 ? (
                  <Button
                    type="submit"
                    color="primary"
                    className={classNames("column pb-0 pt-0 is-6 my-1")}
                    onClick={handleCSVDownload}
                  >
                    Download CSV
                  </Button>
                ) : null}
                {consults?.length > 1 ? (
                  <div>
                    {/* <Button
                      type="submit"
                      color="primary"
                      className={classNames("column pb-0 pt-0 is-6 my-1")}
                      onClick={handleDeleteBatch}
                    >
                      Delete Batch
                    </Button> */}
                    <p className="mt-2">
                      Retrieved {consults?.length} consultations
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
            {loading ? (
              <div className="is-flex is-flex-direction-column is-align-items-center">
                <Loading />
              </div>
            ) : null}
          </Card>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Extractor

export const getFieldTypes = (formFields) => {
  let fieldTypes = {}

  formFields.forEach((field) => {
    fieldTypes = { ...fieldTypes, [field.name]: field?.formFieldType[0] }
  })

  // override/manually set field types
  fieldTypes = {
    ...fieldTypes,
    medicines: "array",
    preferredSchedule: "object",
    created: "dateTime",
    tags: "array",
    startDateTime: "dateTime",
  }

  return fieldTypes
}

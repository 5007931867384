import firebase from "firebase"
import moment from "moment"
import _ from "lodash"

import { getFieldTypes } from "./general"

import { convertBooleanToString } from "services/general"

const flattenArray = (formattedConsult, column, array) => {
  let values = formattedConsult
  let name = column.concat("[0]")

  for (let ctr = 0; ctr < array.length; ctr++) {
    name = name.replace(/.{3}$/, `[${ctr}]`)

    // if array
    if (_.isArray(array[ctr])) flattenArray(values, name, array[ctr])
    // if strictly an object
    else if (
      typeof array[ctr] === "object" &&
      !_.isArray(array[ctr]) &&
      array[ctr] !== null
    ) {
      flattenObject(values, name, array[ctr])
    } else values = { ...values, [name]: array[ctr] }
  }

  return values
}

const flattenObject = (formattedConsult, column, object) => {
  let values = formattedConsult

  for (let key in object) {
    // if array
    if (_.isArray(object[key]))
      flattenArray(values, column.concat(`.${key}`), object[key])
    // if strictly an object
    else if (
      typeof object[key] === "object" &&
      !_.isArray(object[key]) &&
      object[key] !== null
    )
      flattenObject(values, column.concat(`.${key}`), object[key])
    else values[column.concat(`.${key}`)] = object[key]
  }

  values = convertBooleanToString({ data: values })

  return values
}

const formatExtractorValues = (consult, fieldTypes) => {
  let formattedConsult = consult

  for (let key in formattedConsult) {
    switch (fieldTypes[key]) {
      case "dateTime":
        let formattedDateTime = moment
          .unix(formattedConsult[key]?.seconds)
          .format("M/D/YYYY hh:mm:ss")

        if (formattedDateTime !== "Invalid date")
          formattedConsult[key] = formattedDateTime
        else formattedConsult[key] = formattedConsult[key]

        break

      case "date":
        let month =
          formattedConsult[key]?.month ||
          moment.unix(formattedConsult[key]?.seconds).format("MMM")
        let date =
          formattedConsult[key]?.date ||
          moment.unix(formattedConsult[key]?.seconds).format("D")
        let year =
          formattedConsult[key]?.year ||
          moment.unix(formattedConsult[key]?.seconds).format("YYYY")

        let formattedDate = moment(month + " " + date + " " + year).format(
          "M/D/YYYY"
        )

        if (formattedDate !== "Invalid date")
          formattedConsult[key] = formattedDate
        else formattedConsult[key] = null

        break

      case "select":
        formattedConsult[key] = formattedConsult[key]?.value
        break

      case "multiselect":
        let items = []
        formattedConsult[key] = formattedConsult[key][0]
          ? formattedConsult[key]
          : [formattedConsult[key]]
        formattedConsult[key].forEach((item) => {
          items.push(item.value || item)
        })
        formattedConsult[key] = items?.join(", ")
        break

      case "array":
        formattedConsult = flattenArray(
          formattedConsult,
          key,
          formattedConsult[key]
        )
        delete formattedConsult[key]
        break

      case "object":
        formattedConsult = flattenObject(
          formattedConsult,
          key,
          formattedConsult[key]
        )
        delete formattedConsult[key]
        break

      default:
        break
    }
  }
  return formattedConsult
}

const getMedicines = async (noMedConsults) => {
  let consults = []

  await Promise.all(
    noMedConsults.map(async (doc, index) => {
      let currentDocMedicines = []
      let medicineDoc = await doc.medicinesRef.get()

      medicineDoc.forEach((medicine) => {
        currentDocMedicines.push(medicine.data())
      })

      delete doc.medicinesRef
      consults.push({ ...doc, medicines: currentDocMedicines })
    })
  )

  return consults
}

export const getConsultsByTag = async ({
  key,
  operation,
  value,
  formFields,
}) => {
  let consults = []
  let fieldTypes = getFieldTypes(formFields)

  let consultationRef = await firebase
    .firestore()
    .collectionGroup("consultations")
    .where(key, operation, value)
    .get()

  consultationRef.forEach((consult) => {
    consults.push({
      ...consult.data(),
      id: consult.id,
      medicinesRef: consult.ref.collection("medicines"),
    })
  })

  consults = await getMedicines(consults)

  consults = consults.map((consult) => {
    return formatExtractorValues(consult, fieldTypes)
  })

  return consults
}

export const deleteConsultDocument = async ({ consults }) => {
  await Promise.all(
    consults.map(async (consult) => {
      await firebase
        .firestore()
        .collection("users")
        .doc(consult.userId)
        .collection("consultations")
        .doc(consult.id)
        .delete()
    })
  )
}
